import { defineStore } from 'pinia'
import { getAuthMore, initReturnArr } from '@/utils/index.js'
export const useAuth = defineStore('auth', {
  state: () => {
    return {
      authList: {
        auth: false,
        user: false,
        list_user: false,
        add_user: false,
        update_user: false,
        initialize_password: false,
        ban_user: false,
        // 隔开
        character: false,
        add_character: false,
        update_auth: false,
        update_character: false,
        delete_character: false,
        // 隔开
        log: false,
        list_login: false,
        list_message: false,
        list_operate: false,
        // 隔开
        custom: false,
        remittance: false,
        info_verification: false,
        examine_verification: false,
        copy_elist_no: false,
        info_declaration: false,
        download_elist: false,
        // 隔开
        ent: false,
        enterprise: false,
        get_enterprise: false,
        change_manager: false,
        black_list_status: false,
        add_tag: false,
        update_enterprise_type: false,
        update_declare_type: false
      },
    }
  },
  // 相当于计算属性
  getters: {},
  //写异步或同步函数
  actions: {
    initAuth() {
      Object.keys(this.authList).forEach(item => this.authList[item] = false)
    },
    setAuth(dataArr) {
      this.initAuth()
      dataArr.forEach(item => this.authList[item] = true)
    },
    getAuth(data){
      this.setAuth(getAuthMore(data))
      initReturnArr()
    }
  },
})
