<template>
  <div class="role">
    <el-card style="width: 270px; height: 90vh; overflow: scroll">
      <div>
        <span style="line-height: 25px">角色名称</span>
        <el-button
          type="primary"
          v-disabled="{ name: 'add_character' }"
          :icon="Plus"
          @click="addItem"
          circle
          style="width: 25px; height: 25px; float: right"
        />
      </div>
      <div style="color: #666; font-size: 12px; margin-bottom: 20px">
        （双击角色名可编辑名称）
      </div>
      <div style="padding: 0 10px">
        <div
          v-for="(item, index) in roleList"
          :key="index"
          style="margin-bottom: 20px"
        >
          <div v-if="!item.edit" :class="[auId == item.au_id ? 'active' : '']">
            <span style="margin-right: 10px">{{ index + 1 }}</span>
            <span
              @click="getDetail(item.au_id)"
              @dblclick.prevent="handleDoubleClick(item, index)"
              >{{ item.auth_name }}</span
            >
            <span
              v-if="item.auth_name != '管理员'"
              @click="deleteItem(item)"
              style="float: right; cursor: pointer"
              ><el-icon size="17"><Delete /></el-icon
            ></span>
          </div>
          <div
            style="display: flex; border-bottom: 1px solid #666; width: 220px"
            v-else
          >
            <span style="margin-top: 6px">{{ index + 1 }}</span>
            <el-input v-model="item.auth_name" />
            <span
              style="margin-top: 8px; margin-right: 5px; cursor: pointer"
              @click="addORchange(item, index)"
              ><el-icon size="17"><Select /></el-icon
            ></span>
            <span
              style="margin-top: 8px; cursor: pointer"
              @click="deleteORcancel(item, index)"
              ><el-icon size="17"><CloseBold /></el-icon
            ></span>
          </div>
        </div>
      </div>
    </el-card>
    <div style="flex: 1; padding: 0 5px 0 10px">
      <el-card style="min-height: 90vh">
        <el-tabs
          v-model="activeName"
          class="demo-tabs"
          @tab-click="handleClick"
        >
          <el-tab-pane label="操作权限" name="first">
            <div v-show="auId">
              <el-form :disabled="auId == 1 || !Auth.includes('update_auth')">
                <!-- part1 -->
                <el-checkbox
                  @change="checkChange1"
                  v-model="authList.custom"
                  text-color="red"
                  label="外汇归集"
                  size="large"
                />
                <div style="margin-left: 50px">
                  <el-checkbox
                    @change="checkChange2"
                    v-model="authList.remittance"
                    label="汇入汇款"
                    size="large"
                  />
                </div>
                <div style="margin-left: 100px">
                  <el-checkbox
                    @change="checkChange9"
                    v-model="authList.info_verification"
                    label="查看详情"
                    size="large"
                  />
                  <el-checkbox
                    @change="checkChange9"
                    v-model="authList.examine_verification"
                    label="审核管理"
                    size="large"
                  />
                  <el-checkbox
                    @change="checkChange9"
                    v-model="authList.copy_elist_no"
                    label="批量复制清单号"
                    size="large"
                  />
                  <el-checkbox
                    @change="checkChange9"
                    v-model="authList.info_declaration"
                    label="在线查看关单"
                    size="large"
                  />
                  <el-checkbox
                    @change="checkChange9"
                    v-model="authList.download_elist"
                    label="下载清单附件"
                    size="large"
                  />
                </div>
                <el-divider />
                <!-- part2 -->
                <el-checkbox
                  @change="checkChange3"
                  v-model="authList.ent"
                  label="客户管理"
                  size="large"
                />
                <div style="margin-left: 50px">
                  <el-checkbox
                    @change="checkChange4"
                    v-model="authList.enterprise"
                    label="客户列表"
                    size="large"
                  />
                </div>
                <div style="margin-left: 100px">
                  <el-checkbox
                    @change="checkChange10"
                    v-model="authList.get_enterprise"
                    label="查看客户详情"
                    size="large"
                  />
                  <el-checkbox
                    @change="checkChange10"
                    v-model="authList.change_manager"
                    label="分配客户经理"
                    size="large"
                  />
                  <el-checkbox
                    @change="checkChange10"
                    v-model="authList.black_list_status"
                    label="加入黑名单"
                    size="large"
                  />
                  <el-checkbox
                    @change="checkChange10"
                    v-model="authList.add_tag"
                    label="编辑标签"
                    size="large"
                  />
                  <el-checkbox
                    @change="checkChange10"
                    v-model="authList.update_enterprise_type"
                    label="编辑认证类型"
                    size="large"
                  />
                  <el-checkbox
                    @change="checkChange10"
                    v-model="authList.update_declare_type"
                    label="编辑业务类型"
                    size="large"
                  />
                </div>
                <el-divider />
                <!-- part3 -->
                <el-checkbox
                  @change="checkChange5"
                  v-model="authList.auth"
                  label="权限管理"
                  size="large"
                />
                <div style="margin-left: 50px">
                  <el-checkbox
                    @change="checkChange6"
                    v-model="authList.user"
                    label="用户列表"
                    size="large"
                  />
                </div>
                <div style="margin-left: 100px">
                  <el-checkbox
                    @change="checkChange11"
                    v-model="authList.list_user"
                    label="查询"
                    size="large"
                  />
                  <el-checkbox
                    @change="checkChange11"
                    v-model="authList.add_user"
                    label="创建用户"
                    size="large"
                  />
                  <el-checkbox
                    @change="checkChange11"
                    v-model="authList.update_user"
                    label="更新信息"
                    size="large"
                  />
                  <el-checkbox
                    @change="checkChange11"
                    v-model="authList.initialize_password"
                    label="初始化密码"
                    size="large"
                  />
                  <el-checkbox
                    @change="checkChange11"
                    v-model="authList.ban_user"
                    label="禁用"
                    size="large"
                  />
                </div>
                <div style="margin-left: 50px">
                  <el-checkbox
                    @change="checkChange7"
                    v-model="authList.character"
                    label="角色列表"
                    size="large"
                  />
                </div>
                <div style="margin-left: 100px">
                  <el-checkbox
                    @change="checkChange12"
                    v-model="authList.add_character"
                    label="新增角色"
                    size="large"
                  />
                  <el-checkbox
                    @change="checkChange12"
                    v-model="authList.update_auth"
                    label="编辑权限"
                    size="large"
                  />
                  <el-checkbox
                    @change="checkChange12"
                    v-model="authList.update_character"
                    label="修改角色名"
                    size="large"
                  />
                  <el-checkbox
                    @change="checkChange12"
                    v-model="authList.delete_character"
                    label="删除角色"
                    size="large"
                  />
                </div>
                <div style="margin-left: 50px">
                  <el-checkbox
                    @change="checkChange8"
                    v-model="authList.log"
                    label="审计日志"
                    size="large"
                  />
                </div>
                <div style="margin-left: 100px">
                  <el-checkbox
                    @change="checkChange13"
                    v-model="authList.list_login"
                    label="登录日志"
                    size="large"
                  />
                  <el-checkbox
                    @change="checkChange13"
                    v-model="authList.list_message"
                    label="数据操作日志"
                    size="large"
                  />
                  <el-checkbox
                    @change="checkChange13"
                    v-model="authList.list_operate"
                    label="系统操作日志"
                    size="large"
                  />
                </div>
                <el-divider />
                <!-- part4 -->
                <el-checkbox
                  @change="checkChange14"
                  v-model="authList.system_log"
                  label="应用监控"
                  size="large"
                />
                <div style="margin-left: 50px">
                  <el-checkbox
                    @change="checkChange15"
                    v-model="authList.custom_log"
                    label="监控记录"
                    size="large"
                  />
                </div>
                <div style="margin-left: 100px">
                  <el-checkbox
                    @change="checkChange16"
                    v-model="authList.list_declare"
                    label="申报通路"
                    size="large"
                  />
                  <el-checkbox
                    @change="checkChange16"
                    v-model="authList.list_receipt"
                    label="回执通路"
                    size="large"
                  />
                  <el-checkbox
                    @change="checkChange16"
                    v-model="authList.list_api_log"
                    label="应用企业API"
                    size="large"
                  />
                </div>
              </el-form>
            </div>
            <el-empty description="暂无" v-show="!auId" />
          </el-tab-pane>
          <el-tab-pane label="数据权限" name="second">
            <el-radio-group
              v-model="dataType"
              :disabled="auId == 1 || !Auth.includes('update_auth')"
              @change="radioChange"
              style="padding: 0 10px"
              v-show="auId"
            >
              <el-radio label="1" size="large" style="width: 100%"
                >全部（能够查看和操作所有数据）</el-radio
              >
              <el-radio label="2" size="large" style="width: 100%"
                >自己 （只能操作和查看自己的数据）</el-radio
              >
            </el-radio-group>
            <el-empty description="暂无" v-show="!auId" />
          </el-tab-pane>
          <el-tab-pane label="字段权限" name="third">
            <el-empty description="暂无" />
          </el-tab-pane>
        </el-tabs>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "importRoleList",
};
</script>

<script setup>
import { ref, onMounted, watch } from "vue";
import { Plus, Delete, Select, CloseBold } from "@element-plus/icons-vue";
import {
  list_character,
  update_character,
  add_character,
  delete_character,
  get_detail,
  update_auth,
} from "@/api/bank/rights.js";
import { ElMessage } from "element-plus";
import { useAuth } from "@/store/auth";
import { storeToRefs } from "pinia";
import { useCache } from "@/hooks/useCache";

const roleList = ref([]);
const activeName = ref("first");

const { wsCache } = useCache();
const Auth = wsCache.get("Auth");

const getList = () => {
  list_character().then((res) => {
    roleList.value = res.data.list;
    roleList.value.map((item) => (item.edit = false));
  });
};

// 测试权限
const auth = useAuth();
const { authList } = storeToRefs(auth);
// 外汇归集
const group1 = [
  "info_verification",
  "examine_verification",
  "copy_elist_no",
  "info_declaration",
  "download_elist",
];
const checkChange1 = (value) => {
  if (value) {
    authList.value.remittance = true;
    group1.forEach((item) => (authList.value[item] = true));
  } else {
    authList.value.remittance = false;
    group1.forEach((item) => (authList.value[item] = false));
  }
  checkChange();
};
const checkChange2 = (value) => {
  if (value) {
    authList.value.custom = true;
    group1.forEach((item) => (authList.value[item] = true));
  } else {
    authList.value.custom = false;
    group1.forEach((item) => (authList.value[item] = false));
  }
  checkChange();
};
// 三级控制一二级
const checkChange9 = () => {
  let R = false;
  group1.forEach((item) => (R = R || authList.value[item]));
  if (R) {
    authList.value.remittance = true;
    authList.value.custom = true;
  } else {
    authList.value.remittance = false;
    authList.value.custom = false;
  }
  checkChange();
};
// 客户管理
const group2 = [
  "get_enterprise",
  "change_manager",
  "black_list_status",
  "add_tag",
  "update_enterprise_type",
  "update_declare_type",
];
const checkChange3 = (value) => {
  if (value) {
    authList.value.enterprise = true;
    group2.forEach((item) => (authList.value[item] = true));
  } else {
    authList.value.enterprise = false;
    group2.forEach((item) => (authList.value[item] = false));
  }
  checkChange();
};
const checkChange4 = (value) => {
  if (value) {
    authList.value.ent = true;
    group2.forEach((item) => (authList.value[item] = true));
  } else {
    authList.value.ent = false;
    group2.forEach((item) => (authList.value[item] = false));
  }
  checkChange();
};
// 三级控制一二级
const checkChange10 = () => {
  let R = false;
  group2.forEach((item) => (R = R || authList.value[item]));
  if (R) {
    authList.value.ent = true;
    authList.value.enterprise = true;
  } else {
    authList.value.ent = false;
    authList.value.enterprise = false;
  }
  checkChange();
};
// 用户列表
const group3 = [
  "list_user",
  "add_user",
  "update_user",
  "initialize_password",
  "ban_user",
];
const group4 = [
  "add_character",
  "update_auth",
  "update_character",
  "delete_character",
];
const group5 = ["list_login", "list_message", "list_operate"];
const checkChange5 = (value) => {
  if (value) {
    authList.value.user = true;
    authList.value.character = true;
    authList.value.log = true;
    group3.forEach((item) => (authList.value[item] = true));
    group4.forEach((item) => (authList.value[item] = true));
    group5.forEach((item) => (authList.value[item] = true));
  } else {
    authList.value.user = false;
    authList.value.character = false;
    authList.value.log = false;
    group3.forEach((item) => (authList.value[item] = false));
    group4.forEach((item) => (authList.value[item] = false));
    group5.forEach((item) => (authList.value[item] = false));
  }
  checkChange();
};
const checkChange6 = (value) => {
  if (value) {
    authList.value.auth = true;
    group3.forEach((item) => (authList.value[item] = true));
  } else {
    if (!authList.value.character && !authList.value.log)
      authList.value.auth = false;
    group3.forEach((item) => (authList.value[item] = false));
  }
  checkChange();
};
// 角色列表
const checkChange7 = (value) => {
  if (value) {
    authList.value.auth = true;
    group4.forEach((item) => (authList.value[item] = true));
  } else {
    if (!authList.value.user && !authList.value.log)
      authList.value.auth = false;
    group4.forEach((item) => (authList.value[item] = false));
  }
  checkChange();
};
// 审计日志
const checkChange8 = (value) => {
  if (value) {
    authList.value.auth = true;
    group5.forEach((item) => (authList.value[item] = true));
  } else {
    if (!authList.value.user && !authList.value.character)
      authList.value.auth = false;
    group5.forEach((item) => (authList.value[item] = false));
  }
  checkChange();
};
// 三级控制一二级
const checkChange11 = () => {
  let R = false;
  group3.forEach((item) => (R = R || authList.value[item]));
  if (R) {
    authList.value.user = true;
    authList.value.auth = true;
  } else {
    authList.value.user = false;
    if (!authList.value.log && !authList.value.character)
      authList.value.auth = false;
  }
  checkChange();
};
const checkChange12 = () => {
  let R = false;
  group4.forEach((item) => (R = R || authList.value[item]));
  if (R) {
    authList.value.character = true;
    authList.value.auth = true;
  } else {
    authList.value.character = false;
    if (!authList.value.user && !authList.value.log)
      authList.value.auth = false;
  }
  checkChange();
};
const checkChange13 = () => {
  let R = false;
  group5.forEach((item) => (R = R || authList.value[item]));
  if (R) {
    authList.value.log = true;
    authList.value.auth = true;
  } else {
    authList.value.log = false;
    if (!authList.value.user && !authList.value.character)
      authList.value.auth = false;
  }
  checkChange();
};

// 应用监控
const group6 = ["list_declare", "list_receipt", "list_api_log"];
const checkChange14 = (value) => {
  if (value) {
    authList.value.custom_log = true;
    group6.forEach((item) => (authList.value[item] = true));
  } else {
    authList.value.custom_log = false;
    group6.forEach((item) => (authList.value[item] = false));
  }
  checkChange();
};
const checkChange15 = (value) => {
  if (value) {
    authList.value.system_log = true;
    group6.forEach((item) => (authList.value[item] = true));
  } else {
    authList.value.system_log = false;
    group6.forEach((item) => (authList.value[item] = false));
  }
  checkChange();
};
// 三级控制一二级
const checkChange16 = () => {
  let R = false;
  group6.forEach((item) => (R = R || authList.value[item]));
  if (R) {
    authList.value.custom_log = true;
    authList.value.system_log = true;
  } else {
    authList.value.custom_log = false;
    authList.value.system_log = false;
  }
  checkChange();
};

let auId = ref("");
let timeoutFn = null;
const getDetail = (au_id) => {
  timeoutFn = setTimeout(() => {
    if (au_id) {
      auId.value = au_id;
    }
  }, 300);
};

const dataType = ref("");

const authInfo = ref();
watch(auId, (newVal) => {
  get_detail({
    au_id: newVal,
  }).then((res) => {
    authInfo.value = res.data.info;
    dataType.value = String(authInfo.value.data_permission);
    auth.getAuth(res.data.info.operation_permission);
  });
});

const handleDoubleClick = (item, index) => {
  clearTimeout(timeoutFn);
  if (item.auth_name == "管理员") return;
  if (!Auth.includes("update_character")) return;
  roleList.value[index].edit = true;
};

const addItem = () => {
  if (roleList.value[roleList.value.length - 1].edit == true) return;
  roleList.value.push({
    au_id: "",
    auth_name: "",
    edit: true,
    add: true,
  });
};
const addORchange = (item, index) => {
  const regex = /^[\u4e00-\u9fa5a-zA-Z]/;
  if (!regex.test(item.auth_name)) {
    return ElMessage({
      grouping: true,
      showClose: true,
      message: "仅允许中英文开头的角色名",
      type: "error",
    });
  }
  if (item.add) {
    add_character({
      auth_name: item.auth_name,
    }).then((res) => {
      getList();
      ElMessage({
        grouping: true,
        showClose: true,
        message: res.msg,
        type: "success",
      });
    });
  } else {
    update_character({
      au_id: item.au_id,
      auth_name: item.auth_name,
    }).then((res) => {
      getList();
      ElMessage({
        grouping: true,
        showClose: true,
        message: res.msg,
        type: "success",
      });
    });
  }
};

const deleteORcancel = (item, index) => {
  if (item.add) {
    roleList.value = roleList.value.filter((item, index2) => index2 != index);
  } else {
    roleList.value[index].edit = false;
    getList();
  }
};

const deleteItem = (item) => {
  if (!Auth.includes("delete_character")) return;
  delete_character({
    au_id: item.au_id,
  }).then((res) => {
    getList();
    ElMessage({
      grouping: true,
      showClose: true,
      message: res.msg,
      type: "success",
    });
  });
};

const radioChange = async () => {
  const res = await update_auth({
    au_id: auId.value,
    operation_permission: null,
    data_permission: dataType.value,
  });
};

// 重新拼装数据--简单明了
const checkChange = async () => {
  // 设置null利用对象天然对象去重
  const operation_permission = {
    [authList.value.auth ? "auth" : null]: {
      [authList.value.user ? "user" : null]: [
        authList.value.list_user ? "list_user" : null,
        authList.value.add_user ? "add_user" : null,
        authList.value.update_user ? "update_user" : null,
        authList.value.initialize_password ? "initialize_password" : null,
        authList.value.ban_user ? "ban_user" : null,
      ].filter((item) => item != null),
      [authList.value.character ? "character" : null]: [
        authList.value.add_character ? "add_character" : null,
        authList.value.update_auth ? "update_auth" : null,
        authList.value.update_character ? "update_character" : null,
        authList.value.delete_character ? "delete_character" : null,
      ].filter((item) => item != null),
      [authList.value.log ? "log" : null]: [
        authList.value.list_login ? "list_login" : null,
        authList.value.list_message ? "list_message" : null,
        authList.value.list_operate ? "list_operate" : null,
      ].filter((item) => item != null),
    },
    [authList.value.custom ? "custom" : null]: {
      [authList.value.remittance ? "remittance" : null]: [
        authList.value.info_verification ? "info_verification" : null,
        authList.value.examine_verification ? "examine_verification" : null,
        authList.value.copy_elist_no ? "copy_elist_no" : null,
        authList.value.info_declaration ? "info_declaration" : null,
        authList.value.download_elist ? "download_elist" : null,
      ].filter((item) => item != null),
    },
    [authList.value.ent ? "ent" : null]: {
      [authList.value.enterprise ? "enterprise" : null]: [
        authList.value.get_enterprise ? "get_enterprise" : null,
        authList.value.change_manager ? "change_manager" : null,
        authList.value.black_list_status ? "black_list_status" : null,
        authList.value.add_tag ? "add_tag" : null,
        authList.value.update_enterprise_type ? "update_enterprise_type" : null,
        authList.value.update_declare_type ? "update_declare_type" : null,
      ].filter((item) => item != null),
    },
    [authList.value.system_log ? "system_log" : null]: {
      [authList.value.custom_log ? "custom_log" : null]: [
        authList.value.list_declare ? "list_declare" : null,
        authList.value.list_receipt ? "list_receipt" : null,
        authList.value.list_api_log ? "list_api_log" : null,
      ].filter((item) => item != null),
    },
  };
  delete operation_permission[null];
  Object.keys(operation_permission).forEach(
    (item) => delete operation_permission[item][null]
  );
  const res = await update_auth({
    au_id: auId.value,
    operation_permission: operation_permission,
    data_permission: null,
  });
};

onMounted(() => {
  getList();
});
</script>

<style scoped>
.role {
  display: flex;
  margin-top: -8px;
}

:deep(.el-tabs__nav-wrap::after) {
  background-color: #fff !important;
}
.demo-tabs > .el-tabs__content {
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}

/* 单选按钮相关 */
::v-deep .el-radio.el-radio--large .el-radio__inner {
  width: 18px;
  height: 18px;
  border-color: #4a90e2;
  background-color: #fff;
}
::v-deep .el-radio__input.is-checked .el-radio__inner::after {
  width: 9px;
  height: 9px;
  border-radius: var(--el-radio-input-border-radius);
  background-color: #4a90e2;
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
}

.active {
  color: #409eff;
  font-weight: 700;
}
:deep(.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner) {
  background-color: var(--el-checkbox-checked-bg-color);
  border-color: var(--el-checkbox-checked-input-border-color);
}
:deep(.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after) {
  border-color: #fff;
}
:deep(.el-checkbox__input.is-disabled + span.el-checkbox__label) {
  color: #606266;
}
:deep(.el-radio__input.is-disabled + span.el-radio__label) {
  color: #606266;
}
</style>
